.vanRowBoderStyle {
  border-bottom: 1px solid #EDEDED;
  padding: 0.15rem 0 0.2rem;
}
.vanRowBoderStyle .vanCol4PStyle {
  font-size: 0.28rem;
  text-align: right;
  margin-top: 0.25rem;
}
.vanRowBoderStyle .vanCol4PStyle span {
  font-family: Bahnschrift;
}
.vanRowBoderStyle .bottomDivRowColStylePS {
  font-size: 0.28rem;
  line-height: 0.4rem;
  margin-top: 0.08rem;
}
.vanRowBoderStyle .bottomDivRowColStyleP2 {
  font-size: 0.24rem;
  line-height: 0.35rem;
}
.commonTabtit {
  color: #373737;
  font-size: 0.24rem;
  height: 0.8rem;
  border-bottom: 1px solid #EDEDED;
  margin-bottom: 0.2rem;
}
.commonTabtit .type {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.commonTabtit .type li {
  line-height: 0.8rem;
}
.commonTabtit .type li.active {
  font-size: 0.28rem;
  font-weight: bold;
  border-bottom: 2px solid #90C4FF;
}
