

































































































































































































































































.vanRowBoderStyle {
  border-bottom: 1px solid #EDEDED;
  padding: 0.15rem 0 .2rem;
  
  .vanCol4PStyle {
    font-size: 0.28rem;
    text-align: right;
    margin-top: 0.25rem;
	span {
		  font-family: Bahnschrift;
	}
  }
  
  .bottomDivRowColStylePS {
    font-size: .28rem;
    line-height: .4rem;
    margin-top: .08rem;
  }
  
  .bottomDivRowColStyleP2 {
    font-size: 0.24rem;
    line-height: .35rem;
  }
  
}

.commonTabtit {
		color: #373737;
		font-size: .24rem;
		height: .8rem;
		border-bottom: 1px solid #EDEDED;
		margin-bottom: .2rem;
		.type {
			display: flex;
			justify-content: space-between;
			align-items: center;
			li {
				line-height: .8rem;
				// margin-right: .5rem;
				&.active {
					font-size: .28rem;
					font-weight: bold;
					border-bottom: 2px solid #90C4FF;
				}
			}
		}
	}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;