.inputSpan {
  font-size: 0.3rem;
  color: #ff4b04;
  padding-top: 0.2rem;
  float: right;
}
/* .inputSpan {
      font-size: 0.2rem;
      color: #ECBB34;
    } */
.fdStyle {
  background: #f1f1f1;
  height: 100%;
  /* padding: 0.3rem; */
}
.van-ellipsis {
  color: #ffffff !important;
  /* background: green; */
}
.van-nav-bar {
  background: #ecbb34;
}
.van-icon {
  color: #ffffff;
}
.endList {
  margin-top: 2.3rem;
}
.btnDivStyle {
  margin-top: 0.7rem;
  margin-bottom: 0.1rem;
  text-align: center;
}
.bodyStyle {
  background: #f1f1f1;
  height: 100%;
}
.vanCellStyle1 {
  /* margin-left: 0.4rem;
        margin-right: 0.4rem; */
  border-radius: 5px;
  margin-top: 0.2rem;
  /* background: #f1f1f1; */
}
.vanCellStyle2 {
  /* margin-left: 0.4rem;
        margin-right: 0.4rem; */
  border-radius: 5px;
  margin-top: 0.3rem;
  /* background: #f1f1f1; */
}
.van-icon-arrow-left {
  color: #ffffff;
}
.van-hairline--bottom {
  background: #ecbb34;
  color: #ffffff;
}
.van-nav-bar__title {
  background: #ecbb34;
  color: #ffffff;
}
/* .van-ellipsis {
      background: 3d8051;
    } */
.ulList {
  /* margin: 0.3rem; */
  /* padding: 0.3rem;
  background: #ffffff;
  border-radius: 5px; */
}
.inputPapaStyle {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid #f2f2f2;
}
.inputStyle {
  border: 0px;
  /* border-top: 0px;
        border-left: 0px;
        border-right: 0px; */
  width: 100%;
  /* border-bottom: 1px solid #f2f2f2; */
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  color: #8b7575;
  padding: 0.1rem;
}
.whole {
  background: #ffffff;
}
.title {
  background: #f6f8f9;
  height: 2rem;
  padding: 0.3rem;
}
.titleGuanLiStyle {
  font-size: 0.4rem;
  padding-top: 1.5rem;
}
.titlePStyle {
  margin-bottom: 0.2rem;
  margin-top: 0.3rem;
  padding-top: 0.1rem;
  transform: rotate(180deg);
}
.layoutBox {
  min-height: calc(100vh - 0.88rem);
  padding: 0 0.3rem 0.5rem;
}
.ulList {
  padding: 0.1rem 0;
}
.ulList .list-cell {
  height: 0.88rem;
  line-height: 0.88rem;
  padding: 0 0.25rem;
  background: #FFFFFF;
  border-radius: 0.12rem;
  margin-bottom: 0.1rem;
  position: relative;
}
.ulList .list-cell .right {
  position: absolute;
  z-index: 1;
  right: 0.25rem;
  bottom: 0;
}
.contWhiteBox {
  background: #FFFFFF;
  border-radius: 0.12rem;
  margin-bottom: 0.25rem;
}
.list-cell-item {
  padding: 0.2rem 0.25rem;
  border-bottom: 1px solid #EDEDED;
  line-height: 0.45rem;
}
.list-cell-item:last-child {
  border-bottom: 0;
}
.list-cell-item .hd {
  font-size: 0.24rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
}
.list-cell-item .bd {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.list-cell-item .inp {
  font-size: 0.28rem;
  height: 0.45rem;
  display: block;
  width: 100%;
  flex: 1;
  border: 0;
  outline: 0;
}
.list-cell-item input::-webkit-input-placeholder,
.list-cell-item input:-moz-placeholder,
.list-cell-item input::-moz-placeholder,
.list-cell-item input:-ms-input-placeholder {
  color: #999999;
}
.list-cell-item .scanBtn {
  width: 0.45rem;
  height: 0.45rem;
  background: url(../../assets/imgs/icon/icon_scancode.png) no-repeat center center;
  background-size: 0.4rem 0.4rem;
}
.comTips {
  color: #999999;
  font-size: 0.24rem;
  line-height: 0.45rem;
}
